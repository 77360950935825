import React from 'react';
import { Box, CardMedia, Container, Grid2, Typography } from "@mui/material";
import Images from '../../assets/images';
import Colors from '../../style';

function LandingPage() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${Images.bannerBackground})`,
        height: "100vh",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center"
      }}
    >
      <Grid2
        container
        sx={{
          backgroundImage: { md: `url(${Images.banner})`, sm: "none", xs: "none" },
          height: "100%",
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Grid2 size={{ xl: 5, lg: 5, md: 5, sm: 12, xs: 12 }}>
          <Box
            sx={{
              height: "100%",
              display: "flex"
            }}
          >
            <Container>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  height: "100%",
                  py: { md: 0, sm: 2, xs: 2 },
                }}
              >
                <Typography
                  variant='h1'
                  sx={{
                    "-webkit-text-fill-color": "#0000",
                    animation: "effect 2s linear infinite",
                    background: `linear-gradient(90deg, ${Colors.primary}, ${Colors.secondary})`,
                    "-webkit-background-clip": "text",
                    fontWeight: "700 !important",
                    lineHeight: "1.25 !important",
                    fontSize: "60px"
                  }}
                >
                  Where Creators & Businesses Connect
                </Typography>
                <Grid2 container spacing={2}>
                  <Grid2 size={{ md: 6, sm: 6, xs: 6 }}>
                    <Box
                      component={"a"}
                      href="https://apps.apple.com/pk/app/fanoos-gcc/id6683289856"
                      target="_blank"
                    >
                      <CardMedia
                        component={"img"}
                        src={Images.appStoreBtn}
                        sx={{
                          width: "100%",
                          objectFit: "contain"
                        }}
                      />
                    </Box>
                  </Grid2>
                  <Grid2 size={{ md: 6, sm: 6, xs: 6 }}>
                    <Box
                      component={"a"}
                      href="https://play.google.com/store/apps/details?id=com.fanoos.gcc"
                      target="_blank"
                    >
                      <CardMedia
                        component={"img"}
                        src={Images.playStoreBtn}
                        sx={{
                          width: "100%",
                          objectFit: "contain"
                        }}
                      />
                    </Box>
                  </Grid2>
                </Grid2>
              </Box>
            </Container>
          </Box>
        </Grid2>
        <Grid2 size={{ sm: 12, xs: 12 }} display={{ md: "none", sm: "block", xs: "block" }}>
          <CardMedia
            component={"img"}
            src={Images.banner}
            sx={{
              height: "100%",
              width: "100%",
              objectFit: "cover"
            }}
          />
        </Grid2>
      </Grid2>
    </Box>
  )
}

export default LandingPage